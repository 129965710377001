/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Time: any;
};

/** A behaviour in the application */
export type Action = {
  /** The classification of action */
  actionType: ActionType;
};

/** The set of action classifications */
export enum ActionType {
  /** An action to navigate the user to another page */
  ChangePage = 'CHANGE_PAGE',
  /** An action to display a menu */
  DisplayMenu = 'DISPLAY_MENU',
  OnFocus = 'ON_FOCUS',
  PageView = 'PAGE_VIEW',
  /** An action to play on-demand content */
  PlayHighlight = 'PLAY_HIGHLIGHT',
  /** An action to play live stream */
  PlayLive = 'PLAY_LIVE',
  /** An action to track an onClick event */
  SnowplowEvent = 'SNOWPLOW_EVENT',
  /** An action for fetching additional data */
  FetchData = 'FETCH_DATA',
  /** An action to send a request to specific URL, not expecting or waiting for a response */
  Beacon = 'BEACON',
}

export type Element = {
  actions?: Maybe<Actions>;
  id: Scalars['ID'];
  type: ElementType;
};

export type LifecycleEvents = {
  actions?: Maybe<Actions>;
};

/** The set of hero element classifications */
export enum ElementType {
  Unknown = 'UNKNOWN',
  /** The main heading element */
  Title = 'TITLE',
  /** Additional data describing the main content e.g. the genre of a TV Series */
  Metadata = 'METADATA',
  /** The description of the main content */
  Description = 'DESCRIPTION',
  /** Clickable elements which perform certain actions e.g. navigating to another page */
  Buttons = 'BUTTONS',
  Image = 'IMAGE',
  Card = 'CARD',
  ThumbnailCard = 'THUMBNAIL_CARD',
  Tab = 'TAB',
  HorizontalTab = 'HORIZONTAL_TAB',
  PosterTab = 'POSTER_TAB',
  ThumbnailTab = 'THUMBNAIL_TAB',
  InlineText = 'INLINE_TEXT',
  Actions = 'ACTIONS',
  /** Contains EPG data for Now On and Next program */
  Widget = 'WIDGET',
  Background = 'BACKGROUND',
  Button = 'BUTTON',
  Tracking = 'TRACKING',
}

export type PromoRailModel = {
  type: Scalars['String'];
};

export type SwitcherRailElement = {
  type: Scalars['String'];
  switcherLogo?: Maybe<SwitcherLogoImageModel>;
  signpost?: Maybe<Scalars['String']>;
};

export type Tab = {
  actions?: Maybe<Actions>;
  id: Scalars['ID'];
  type: ElementType;
  /** The name of the tab displayed on the tab */
  title: Scalars['String'];
  /** The name of the tab displayed alongside additional information about the tab */
  heading?: Maybe<Scalars['String']>;
  /** The description of the tab */
  description?: Maybe<Scalars['String']>;
  /** The cards in this tab group */
  cards: Array<Maybe<Element>>;
  /** The type of tab */
  tabType: TabTypes;
  /** The name of the tab displayed on the tab */
  tabId?: Maybe<Scalars['String']>;
  /** Number of cards in the tab */
  cardCount: Scalars['Float'];
};

/** The different types of tab */
export enum TabTypes {
  HorizontalTab = 'HorizontalTab',
  PosterTab = 'PosterTab',
  ThumbnailTab = 'ThumbnailTab',
}

/** An visual element on the page. Often contains other elements and is generally associated with a section. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Components) */
export type Component = {
  actions?: Maybe<Actions>;
  /** The classification of this component */
  componentType: ComponentType;
};

/** The set of component classifications */
export enum ComponentType {
  /** A section displayed at the top of the page */
  Hero = 'HERO',
  /** A section containing a row of switchable tabs above a grid of cards associated with the active tab */
  TabbedGrid = 'TABBED_GRID',
  /** Video component */
  Video = 'VIDEO',
}

/** The sections in a page layout */
export type Sections = {
  /** The classification of this set of sections. Associated with a layout type */
  sectionsType: SectionTypes;
};

/** The set of classifications for sets of sections */
export enum SectionTypes {
  /** Header and content sections */
  StandardSections = 'STANDARD_SECTIONS',
  /** Header, content, and background sections */
  VideoBackgroundSections = 'VIDEO_BACKGROUND_SECTIONS',
}

/** A page layout including the sections within it. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Layouts) */
export type Layout = {
  actions?: Maybe<Actions>;
  /** The classification of this page layout */
  layoutType: LayoutTypes;
  /** The sections within this page layout */
  sections: Sections;
  /** The metadata associated with this layout */
  metadata?: Maybe<LayoutMetadata>;
};

/** The set of page layout classifications which determine the sections on a page */
export enum LayoutTypes {
  /** A page layout containing header and content sections */
  StandardLayout = 'STANDARD_LAYOUT',
  /** Layout containing header, content, and background sections */
  VideoBackgroundLayout = 'VIDEO_BACKGROUND_LAYOUT',
}

export type StartOverLocation = {
  __typename?: 'StartOverLocation';
  /** The time (ISO date string) to seek/jump to in a stream to Start Over. A null time represents the current time which should be calculated by the consumer of this field */
  time: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  /** The type of page at this location e.g. `TV_SERIES_PAGE`, `LONGFORM_WATCH` */
  locationType: LocationType;
  /** The URL path for this asset */
  slug?: Maybe<Scalars['String']>;
  /** The unique short name for a TV Series e.g. `the-block` */
  tvSeriesSlug?: Maybe<Scalars['String']>;
  /** The unique short name for a Season e.g. `season-1` */
  seasonSlug?: Maybe<Scalars['String']>;
  /** Details of a Start Over destination */
  startOver?: Maybe<StartOverLocation>;
  /** Video duration in seconds watched by the user */
  progress?: Maybe<Scalars['Float']>;
  /** Live stream URL path for Radio */
  streamUrl?: Maybe<Scalars['String']>;
  /** Show ID number */
  showId?: Maybe<Scalars['String']>;
  /** Application deeplink uri */
  deeplink?: Maybe<Scalars['String']>;
};

/** The type of a location */
export enum LocationType {
  LiveEvent = 'LIVE_EVENT',
  Channel = 'CHANNEL',
  TvSeriesPage = 'TV_SERIES_PAGE',
  TvSeasonPage = 'TV_SEASON_PAGE',
  LongformWatch = 'LONGFORM_WATCH',
  ShortformWatch = 'SHORTFORM_WATCH',
  Genre = 'GENRE',
  UpcomingEvent = 'UPCOMING_EVENT',
  RadioShowPage = 'RADIO_SHOW_PAGE',
  DeeplinkUri = 'DEEPLINK_URI',
}

/** An action to navigate the user to another page */
export type ChangePageAction = Action & {
  __typename?: 'ChangePageAction';
  /** The classification of this action */
  actionType: ActionType;
  /** The destination page */
  location: Location;
};

/** An action indicating that a page has been viewed */
export type PageViewAction = Action & {
  __typename?: 'PageViewAction';
  /** The classification of this action */
  actionType: ActionType;
};

export type SnowplowEvent = Action & {
  __typename?: 'SnowplowEvent';
  /** The classification of this action */
  actionType: ActionType;
  schema: Scalars['String'];
  data: Scalars['JSON'];
};

/** An action to start playing livestream */
export type PlayLiveAction = Action & {
  __typename?: 'PlayLiveAction';
  /** The classification of this action */
  actionType: ActionType;
  /** Station live streaming URL */
  streamUrl: Scalars['String'];
  /** Show ID */
  showId: Scalars['String'];
  /** Show title */
  showTitle: Scalars['String'];
};

export type Clip = {
  __typename?: 'Clip';
  /** Audio clip URL */
  audioUrl: Scalars['String'];
  /** Clip Duration In Seconds */
  duration: Scalars['Float'];
  /** Clip ID */
  id: Scalars['String'];
  /** w768 Clip image url */
  image: Scalars['String'];
  /** Clip title */
  title: Scalars['String'];
  /** Show title */
  showTitle: Scalars['String'];
};

/** An action to start playing on-demand content */
export type PlayHighlightAction = Action & {
  __typename?: 'PlayHighlightAction';
  /** The classification of this action */
  actionType: ActionType;
  /** Clip to play */
  clip: Clip;
  /** Show ID that the Clip Belongs to */
  showId: Scalars['String'];
};

/** An action indicating that a page has been viewed */
export type FetchDataAction = Action & {
  __typename?: 'FetchDataAction';
  /** The classification of this action */
  actionType: ActionType;
  variables?: Maybe<Scalars['JSON']>;
};

export type HttpHeader = {
  __typename?: 'HTTPHeader';
  /** The key of HTTP Header e.g. Content-Type */
  key: Scalars['String'];
  /** The value of HTTP Header key e.g. application/json */
  value: Scalars['String'];
};

/**
 *
 *     BeaconAction is used client-side. It indicates that a client
 *     should fire off an arbitrary beacon to a provided URL and is
 *     used in tracking scenarios
 *
 */
export type BeaconAction = Action & {
  __typename?: 'BeaconAction';
  actionType: ActionType;
  /** URL of beacon action */
  url: Scalars['String'];
  /** HTTP header of url tied to this beacon action */
  headers: Array<Maybe<HttpHeader>>;
};

/** An item for a dropdown menu */
export type MenuItem = {
  __typename?: 'MenuItem';
  /** The text to display on the menu item. May be empty if only icon is to be displayed */
  text?: Maybe<Scalars['String']>;
  /** The icon to display on the menu */
  icon?: Maybe<IconType>;
  /** The destination */
  location: Location;
};

/** The name of an icon */
export enum IconType {
  Play = 'PLAY',
  Bell = 'BELL',
  Lightning = 'LIGHTNING',
  Stack = 'STACK',
  Tick = 'TICK',
  Clock = 'CLOCK',
  Info = 'INFO',
  BackArrow = 'BACK_ARROW',
  Categories = 'CATEGORIES',
  Phone = 'PHONE',
  Message = 'MESSAGE',
  ChatBubble = 'CHAT_BUBBLE',
}

/** An action to show dropdown menu */
export type DisplayMenuAction = Action & {
  __typename?: 'DisplayMenuAction';
  /** The classification of this action */
  actionType: ActionType;
  /** Menu items */
  menuItems: Array<MenuItem>;
  /** Menu title */
  menuTitle: Scalars['String'];
};

export type Actions = {
  __typename?: 'Actions';
  /** The actions to perform on a click event */
  onClick: Array<Maybe<Action>>;
  /** The actions to perform on a focus event */
  onFocus: Array<Maybe<Action>>;
  /** The actions to perform on a load event */
  onLoad: Array<Maybe<Action>>;
};

/** Metadata related to brand tracking */
export type BrandTrackingMetadata = {
  __typename?: 'BrandTrackingMetadata';
  schema: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
};

export type LiveConfig = {
  __typename?: 'LiveConfig';
  preLivePeriod: Scalars['Time'];
  fhdPromoMessage: Scalars['String'];
};

export type CacheConfig = {
  __typename?: 'CacheConfig';
  drmTokenTtl: Scalars['Time'];
  liveMaxAge: Scalars['Time'];
};

export type AuthenticationConfig = {
  __typename?: 'AuthenticationConfig';
  ssoUrl: Scalars['String'];
  jwksUrl: Scalars['String'];
};

export type TvApiConfig = {
  __typename?: 'TvApiConfig';
  tvApiUrl: Scalars['String'];
};

export type RadioApiConfig = {
  __typename?: 'RadioApiConfig';
  radioApiUrl: Scalars['String'];
};

export type UserApiConfig = {
  __typename?: 'UserApiConfig';
  userApiUrl: Scalars['String'];
};

export type QueriesConfig = {
  __typename?: 'QueriesConfig';
  queriesBucket: Scalars['String'];
  queriesFiles?: Maybe<Scalars['JSON']>;
  queriesMaxAge: Scalars['Time'];
  queriesRefreshInterval: Scalars['Time'];
};

export type TokenisationSecretsConfig = {
  __typename?: 'TokenisationSecretsConfig';
  cronInterval: Scalars['String'];
};

export type SsoSdkConfig = {
  __typename?: 'SsoSDKConfig';
  expiresIn: Scalars['Time'];
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  transform401To500Response: Scalars['Boolean'];
  supportsTokenisation: Scalars['Boolean'];
};

export type SecretsManagerConfig = {
  __typename?: 'SecretsManagerConfig';
  region: Scalars['String'];
  secretName: Scalars['String'];
  secretCacheTime: Scalars['Time'];
};

export type Tagline = {
  __typename?: 'Tagline';
  dayTimeHours: Scalars['Float'];
  dayTimeMinutes: Scalars['Float'];
};

export type SnowplowConfig = {
  __typename?: 'SnowplowConfig';
  brand: BrandTrackingMetadata;
  pageSchema: Scalars['String'];
  pageTransitionSchema: Scalars['String'];
};

export type Tracking = {
  __typename?: 'Tracking';
  snowplow: SnowplowConfig;
};

export type UnleashConfig = {
  __typename?: 'UnleashConfig';
  url: Scalars['String'];
  appName: Scalars['String'];
  unleashEnvironment: Scalars['String'];
};

export type Config = {
  __typename?: 'Config';
  version: Scalars['String'];
  queries: QueriesConfig;
  tokenisationSecrets: TokenisationSecretsConfig;
  live: LiveConfig;
  cache: CacheConfig;
  authentication: AuthenticationConfig;
  tvApi: TvApiConfig;
  radioApi: RadioApiConfig;
  userApi: UserApiConfig;
  secretsManager: Array<SecretsManagerConfig>;
  ssoSDK: SsoSdkConfig;
  featureFlags: FeatureFlags;
  unleash: UnleashConfig;
  tagline: Tagline;
  tracking: Tracking;
};

export type MarketModel = {
  __typename?: 'MarketModel';
  id: Scalars['Float'];
  name: Scalars['String'];
  regionId: Scalars['Float'];
  oztamPublisherId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type LiveExperienceRegionModel = {
  __typename?: 'LiveExperienceRegionModel';
  short?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type SeekButtonModel = ButtonModel & {
  __typename?: 'SeekButtonModel';
  /** The text to display on the button. May be empty if only icon is to be displayed */
  text: Scalars['String'];
  /** The icon to display on the button */
  icon?: Maybe<Scalars['String']>;
  /** The time (ISO date string) to seek/jump to when this button is interacted with. A null time represents the current time which should be calculated by the consumer of this field */
  time?: Maybe<Scalars['String']>;
};

export type ButtonModel = {
  /** The text to display on the button. May be empty if only icon is to be displayed */
  text?: Maybe<Scalars['String']>;
  /** The icon to display on the button */
  icon?: Maybe<Scalars['String']>;
};

export type ButtonsModel = {
  __typename?: 'ButtonsModel';
  /** A button to jump to the start of the current program */
  startOverButton?: Maybe<SeekButtonModel>;
  /** A button to jump to live with no time offset */
  jumpToLiveButton?: Maybe<SeekButtonModel>;
  /** A button to jump to the start of the next program */
  watchFromStartButton?: Maybe<SeekButtonModel>;
};

export type CrtOutputProtectionModel = {
  __typename?: 'CrtOutputProtectionModel';
  digital?: Maybe<Scalars['Boolean']>;
  analogue?: Maybe<Scalars['Boolean']>;
  enforce?: Maybe<Scalars['Boolean']>;
};

export type CrtProfileRentalModel = {
  __typename?: 'CrtProfileRentalModel';
  absoluteExpiration?: Maybe<Scalars['String']>;
  playDuration?: Maybe<Scalars['Float']>;
};

export type CrtProfileModel = {
  __typename?: 'CrtProfileModel';
  rental?: Maybe<CrtProfileRentalModel>;
};

export type CrtModel = {
  __typename?: 'CrtModel';
  accountingId?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  profile?: Maybe<CrtProfileModel>;
  outputProtection?: Maybe<CrtOutputProtectionModel>;
};

export type DrmModel = {
  __typename?: 'DrmModel';
  provider?: Maybe<Scalars['String']>;
  merchantName?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  keyRotationId?: Maybe<Scalars['Float']>;
  crt: Array<CrtModel>;
  authToken?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['String']>;
};

export type SsaiModel = {
  __typename?: 'SsaiModel';
  postfixParams?: Maybe<Scalars['String']>;
};

export type DrmStreamModel = {
  __typename?: 'DrmStreamModel';
  url?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  fallbackId?: Maybe<Scalars['String']>;
  streamType?: Maybe<Scalars['String']>;
  hasDrm?: Maybe<Scalars['Boolean']>;
  isDai?: Maybe<Scalars['Boolean']>;
  quality?: Maybe<StreamQuality>;
  signpost?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  oztamPublisherId?: Maybe<Scalars['String']>;
  ssai?: Maybe<SsaiModel>;
  drm?: Maybe<DrmModel>;
};

/** A representation of the quality of a stream e.g. `HD`, `FHD` */
export enum StreamQuality {
  Hd = 'HD',
  Fhd = 'FHD',
}

export type ImageSizesModel = {
  __typename?: 'ImageSizesModel';
  w320?: Maybe<Scalars['String']>;
  w480?: Maybe<Scalars['String']>;
  w768?: Maybe<Scalars['String']>;
  w1280?: Maybe<Scalars['String']>;
  w1920?: Maybe<Scalars['String']>;
};

export type SsoImageModel = {
  __typename?: 'SsoImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type SecondaryListingModel = {
  __typename?: 'SecondaryListingModel';
  title?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
};

export type PromoImageModel = {
  __typename?: 'PromoImageModel';
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type StreamListingModel = {
  __typename?: 'StreamListingModel';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  promoStartDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  genreString?: Maybe<Scalars['String']>;
  /** @deprecated It has been deprecated in favor of secondaryListing. */
  upNextTitle?: Maybe<Scalars['String']>;
  /** @deprecated It has been deprecated in favor of secondaryListing. */
  upNextSubtitle?: Maybe<Scalars['String']>;
  hasStreamingRights?: Maybe<Scalars['Boolean']>;
  tvSeriesSlug?: Maybe<Scalars['String']>;
  secondaryListing?: Maybe<SecondaryListingModel>;
};

export type DisplayModel = {
  __typename?: 'DisplayModel';
  color?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  promoImage?: Maybe<PromoImageModel>;
  ssoImage?: Maybe<SsoImageModel>;
  listings?: Maybe<Array<StreamListingModel>>;
  buttons?: Maybe<ButtonsModel>;
};

export type StreamModel = {
  __typename?: 'StreamModel';
  type?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  video?: Maybe<DrmStreamModel>;
  display?: Maybe<DisplayModel>;
};

export type PromoRailItemImageModel = {
  __typename?: 'PromoRailItemImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type PromoSeriesModel = PromoRailModel & {
  __typename?: 'PromoSeriesModel';
  type: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PromoSeriesItemModel>>;
};

export type PromoSeriesItemModel = {
  __typename?: 'PromoSeriesItemModel';
  id?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  image?: Maybe<PromoRailItemImageModel>;
};

export type PromoLiveEventRail = PromoRailModel & {
  __typename?: 'PromoLiveEventRail';
  type: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PromoLiveEventRailItem>>;
};

export type PromoLiveEventRailItem = {
  __typename?: 'PromoLiveEventRailItem';
  id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  promoStartDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  image?: Maybe<PromoRailItemImageModel>;
};

export type SwitcherLogoImageModel = {
  __typename?: 'SwitcherLogoImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type SwitcherRailLiveEventModel = {
  __typename?: 'SwitcherRailLiveEventModel';
  /** @deprecated Deprecated in favour of stream.display.listings */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  subtitle?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  description?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  genreName?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  classificationCode?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  programStartTime?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  programEndTime?: Maybe<Scalars['String']>;
};

export type SwitcherRailLiveEventGroupModel = SwitcherRailElement & {
  __typename?: 'SwitcherRailLiveEventGroupModel';
  type: Scalars['String'];
  switcherLogo?: Maybe<SwitcherLogoImageModel>;
  signpost?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  eventCount?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  groupStartTime?: Maybe<Scalars['String']>;
  groupEndTime?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  isChannelGroup?: Maybe<Scalars['Boolean']>;
  programStartDate?: Maybe<Scalars['String']>;
  programEndDate?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  currentEvent?: Maybe<SwitcherRailLiveEventModel>;
  /** @deprecated Deprecated in favour of stream.display.listings */
  nextEvent?: Maybe<SwitcherRailLiveEventModel>;
  currentProgramName?: Maybe<Scalars['String']>;
};

export type AiringModel = {
  __typename?: 'AiringModel';
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  hasStreamingRights?: Maybe<Scalars['Boolean']>;
};

export type SwitcherRailChannelModel = SwitcherRailElement & {
  __typename?: 'SwitcherRailChannelModel';
  type: Scalars['String'];
  switcherLogo?: Maybe<SwitcherLogoImageModel>;
  signpost?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  airings?: Maybe<Array<AiringModel>>;
  quality?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type TvApiLiveExperience = {
  __typename?: 'TvApiLiveExperience';
  market: MarketModel;
  region: LiveExperienceRegionModel;
  stream: StreamModel;
  switcherRail?: Maybe<Array<SwitcherRailElement>>;
  promoRail?: Maybe<PromoRailModel>;
};

/** A row of switchable tabs above a grid of cards associated with the active tab. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Tabbed-Grid) */
export type TabbedGrid = Component &
  LifecycleEvents & {
    __typename?: 'TabbedGrid';
    /** The classification of this component */
    componentType: ComponentType;
    actions?: Maybe<Actions>;
    /** The tabs in this tabbed grid */
    tabs: Array<Tab>;
  };

export type Image = {
  __typename?: 'Image';
  /** The URL to the image */
  url: Scalars['String'];
  /**
   * The alt text describing the image
   * @deprecated It has been moved up a level as a direct child of ImageSize
   */
  alt: Scalars['String'];
};

/** An object containing renditions of an image in various sizes (based on pixel width) */
export type ImageSize = {
  __typename?: 'ImageSize';
  /** The alt text describing the image */
  alt: Scalars['String'];
  srcset: Scalars['String'];
  w320: Image;
  w480: Image;
  w768: Image;
  w1280: Image;
  w1920: Image;
};

/** An object containing renditions of an image in various file formats. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Image) */
export type ImageType = Element &
  LifecycleEvents & {
    __typename?: 'ImageType';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** Renditions of the image in various sizes in the default format (generally PNG) */
    default: ImageSize;
    /** Renditions of the image in various sizes in the WebP format */
    webp: ImageSize;
  };

/** A text element including its formatting. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Inline-Text-Element) */
export type InlineTextAndIconElement = Element &
  LifecycleEvents & {
    __typename?: 'InlineTextAndIconElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The text of the text element */
    text: Scalars['String'];
    /** The type of formatting for the text */
    displayType: InlineTextDisplayTypes;
    /** Optional icon to be displayed with Text */
    icon?: Maybe<IconType>;
    /** The hex color code of the icon - If no value is present then client will use Light Grey color for the icon */
    iconColor?: Maybe<Scalars['String']>;
    /** The hex colour code of the text e.g. `#defec8` */
    highlightColor?: Maybe<Scalars['String']>;
  };

/** The different visual classifications of text elements */
export enum InlineTextDisplayTypes {
  StandardText = 'STANDARD_TEXT',
  BackgroundBlockText = 'BACKGROUND_BLOCK_TEXT',
  ChannelLogo = 'CHANNEL_LOGO',
}

/** A widget element that appears in the corner of page and contains livestream metadata */
export type WidgetElement = Element &
  LifecycleEvents & {
    __typename?: 'WidgetElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** Label and icon attached on the front of widget e.g. "Live" */
    tag: InlineTextAndIconElement;
    /** Channel logo for the live show playing */
    logo?: Maybe<Scalars['String']>;
    /** Main text on the widget */
    title: InlineTextAndIconElement;
    /** Secondary text on the widget */
    subtitle: InlineTextAndIconElement;
  };

/** provides tracking related data */
export type TrackingElement = Element &
  LifecycleEvents & {
    __typename?: 'TrackingElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** oztamPublisherID associated with the stream */
    publisherId: Scalars['String'];
    /** This is always set to "live" */
    mediaType: Scalars['String'];
    /** This is same value as publisherId */
    mediaId: Scalars['String'];
    /** This represents the channel slug or the event slug associated with video */
    channelName: Scalars['String'];
    /** This value provides PageTypes supported by OzTam */
    pageType: Scalars['String'];
  };

/** Video background component for hero element */
export type Video = Component &
  LifecycleEvents & {
    __typename?: 'Video';
    /** The classification of this component */
    componentType: ComponentType;
    actions?: Maybe<Actions>;
    /** Live stream url */
    liveChannelStream?: Maybe<Scalars['String']>;
    /** Image to be displayed if live channel stream is not working */
    image: ImageType;
    /** Widget element */
    widget: WidgetElement;
    /** Provides Tracking information */
    tracking: TrackingElement;
  };

/** Video background component for hero element */
export type VideoImageArgs = {
  imageArgs?: InputMaybe<ImageArgs>;
};

export type ImageArgs = {
  /** An optional argument for video background */
  imageType?: InputMaybe<Scalars['String']>;
};

/** A hero element containing the description of the main content */
export type DescriptionElement = Element &
  LifecycleEvents & {
    __typename?: 'DescriptionElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The text of the description */
    text: Scalars['String'];
  };

/** A hero element for the main heading */
export type TitleElement = Element &
  LifecycleEvents & {
    __typename?: 'TitleElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The text of the main heading */
    text: Scalars['String'];
    /** An optional logo displayed with the main heading e.g. a 'The Block' logo on The Block's TV Series page */
    logo?: Maybe<ImageType>;
  };

/** A button with associated actions used in a hero element */
export type Button = Element &
  LifecycleEvents & {
    __typename?: 'Button';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The text to display on the button. May be empty if only icon is to be displayed */
    text?: Maybe<Scalars['String']>;
    /** The type of formatting for the button */
    displayType: ButtonDisplayTypes;
    /** The icon to display on the button */
    icon?: Maybe<IconType>;
  };

/** The different visual classifications of button elements */
export enum ButtonDisplayTypes {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
  Select = 'SELECT',
  Hidden = 'HIDDEN',
  Disabled = 'DISABLED',
}

/** A hero element containing a set of buttons and their associated actions */
export type ButtonsElement = Element &
  LifecycleEvents & {
    __typename?: 'ButtonsElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The set of buttons and their associated actions */
    buttons: Array<Button>;
  };

/** A hero metadata element containing data for a progress bar */
export type MetadataProgress = {
  __typename?: 'MetadataProgress';
  /** A numeric percentage (0 - 100) e.g. `95` */
  percent: Scalars['Float'];
  /** The hex colour code for the progress bar e.g. `#defec8` */
  color: Scalars['String'];
};

/** A hero element containing metadata about the main content */
export type MetadataElement = Element &
  LifecycleEvents & {
    __typename?: 'MetadataElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** Additional text data describing the main content e.g. the genre of a TV Series */
    metadata: Array<InlineTextAndIconElement>;
    /** The percentage completed (0 - 100) and colour for a progress bar */
    progress?: Maybe<MetadataProgress>;
    /** Renditions of an image in various formats and sizes */
    image?: Maybe<ImageType>;
  };

/** The background of the page */
export type HeroBackground = {
  __typename?: 'HeroBackground';
  /** Renditions of the image in various formats and sizes */
  image?: Maybe<ImageType>;
};

/** The background of the page */
export type HeroBackgroundImageArgs = {
  imageArgs?: InputMaybe<ImageArgs>;
};

/** The group of elements displayed at the top of a page's main contents. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Hero) */
export type Hero = Component &
  LifecycleEvents & {
    __typename?: 'Hero';
    /** The classification of this component */
    componentType: ComponentType;
    actions?: Maybe<Actions>;
    /** The elements in the hero e.g. `TITLE`, `METADATA`, `BUTTONS` */
    elements: Array<HeroElement>;
    /** The image displayed behind the hero elements */
    background: HeroBackground;
    /** Sponsorship Image field */
    sponsorImageUrl?: Maybe<Scalars['String']>;
  };

export type HeroElement = TitleElement | DescriptionElement | MetadataElement | ButtonsElement;

/** An element representing some catalogue content e.g. an Episode or TV Series. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Card) */
export type CardElement = Element &
  LifecycleEvents & {
    __typename?: 'CardElement';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The title to display when the card is focused on CTV experiences */
    title: TitleElement;
    /** Element to be displayed underneath the card, displaying primary information about the content */
    primaryTitle: MetadataElement;
    /** The description of the main content */
    description: DescriptionElement;
    /** The image thumbnail of the card */
    cardImage?: Maybe<ImageType>;
    /** Optional background image for client teams who needs it */
    backgroundImage?: Maybe<ImageType>;
    /** The buttons associated with this card */
    secondaryActions: ButtonsElement;
    /** The buttons associated with this card  */
    primaryActions: ButtonsElement;
    /** The progress through a piece of content */
    progress?: Maybe<MetadataProgress>;
    /** Element to be displayed at the top of the card, displaying secondary information about the content e.g. "New season", "21 mins left" */
    secondaryTitle?: Maybe<MetadataElement>;
    /** Text describing associated details for content */
    secondaryContent?: Maybe<MetadataElement>;
    /** Text describing when the content is scheduled e.g. "Aired 24th May 2022", "JUST ADDED" */
    tertiaryContent?: Maybe<MetadataElement>;
    /** Represents array of elements which provide metadata info about the entity(series, season etc.) represented by the card */
    additionalInfo: MetadataElement;
    /** Sponsorship Image field */
    sponsorImageUrl?: Maybe<Scalars['String']>;
  };

/** A type of tab - HorizontalTab */
export type HorizontalTab = Tab &
  Element &
  LifecycleEvents & {
    __typename?: 'HorizontalTab';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The name of the tab displayed on the tab */
    title: Scalars['String'];
    /** The name of the tab displayed alongside additional information about the tab */
    heading?: Maybe<Scalars['String']>;
    /** The description of the tab */
    description?: Maybe<Scalars['String']>;
    /** The cards in this HorizontalTab group */
    cards: Array<Maybe<CardElement>>;
    /** The type of tab */
    tabType: TabTypes;
    /** The name of the tab displayed on the tab */
    tabId?: Maybe<Scalars['String']>;
    /** Number of cards in the tab */
    cardCount: Scalars['Float'];
  };

/** A type of tab - PosterTab */
export type PosterTab = Tab &
  Element &
  LifecycleEvents & {
    __typename?: 'PosterTab';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The name of the tab displayed on the tab */
    title: Scalars['String'];
    /** The name of the tab displayed alongside additional information about the tab */
    heading?: Maybe<Scalars['String']>;
    /** The description of the tab */
    description?: Maybe<Scalars['String']>;
    /** The cards in this PosterTab group */
    cards: Array<Maybe<CardElement>>;
    /** The type of tab */
    tabType: TabTypes;
    /** The name of the tab displayed on the tab */
    tabId?: Maybe<Scalars['String']>;
    /** Number of cards in the tab */
    cardCount: Scalars['Float'];
  };

/** An element representing some catalogue content with small picture. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Card) */
export type ThumbnailCard = Element &
  LifecycleEvents & {
    __typename?: 'ThumbnailCard';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** Element to be displayed underneath the card, displaying primary information about the content */
    primaryTitle: MetadataElement;
    /** The image thumbnail of the card */
    cardImage?: Maybe<ImageType>;
    /** The buttons associated with this card  */
    primaryActions: ButtonsElement;
    /** The progress through a piece of content */
    progress?: Maybe<MetadataProgress>;
    /** Element to be displayed at the top of the card, displaying secondary information about the content e.g. "New season", "21 mins left" */
    secondaryTitle?: Maybe<MetadataElement>;
    /** Text describing associated details for content */
    secondaryContent?: Maybe<MetadataElement>;
    /** Text describing when the content is scheduled e.g. "Aired 24th May 2022", "JUST ADDED" */
    tertiaryContent?: Maybe<MetadataElement>;
  };

/** A type of tab - ThumbnailTab */
export type ThumbnailTab = Tab &
  Element &
  LifecycleEvents & {
    __typename?: 'ThumbnailTab';
    actions?: Maybe<Actions>;
    id: Scalars['ID'];
    type: ElementType;
    /** The name of the tab displayed on the tab */
    title: Scalars['String'];
    /** The name of the tab displayed alongside additional information about the tab */
    heading?: Maybe<Scalars['String']>;
    /** The description of the tab */
    description?: Maybe<Scalars['String']>;
    /** The cards in this ThumbnailTab group */
    cards: Array<ThumbnailCard>;
    /** The type of tab */
    tabType: TabTypes;
    /** The name of the tab displayed on the tab */
    tabId?: Maybe<Scalars['String']>;
    /** Number of cards in the tab */
    cardCount: Scalars['Float'];
  };

/** Metadata related to page tracking */
export type PageTrackingMetadata = {
  __typename?: 'PageTrackingMetadata';
  schema: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
};

/** Metadata related to Snowplow tracking */
export type SnowplowTrackingMetadata = {
  __typename?: 'SnowplowTrackingMetadata';
  /** The metadata associated with brand-level tracking */
  brand: BrandTrackingMetadata;
  /** The metadata associated with page-level tracking */
  page: PageTrackingMetadata;
};

/** The metadata associated with a layout */
export type LayoutMetadata = {
  __typename?: 'LayoutMetadata';
  /** The Snowplow tracking metadata associated with this layout */
  tracking?: Maybe<SnowplowTrackingMetadata>;
};

/** The sections in the standard page layout */
export type StandardLayoutSections = Sections & {
  __typename?: 'StandardLayoutSections';
  /** The classification of the set of sections on this page: `STANDARD_SECTIONS` */
  sectionsType: SectionTypes;
  /** The component displayed at the top of the page's main contents */
  header: Component;
  /** The main content component on the page */
  content: Component;
};

/** The standard page layout and sections. [See designs](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#Standard-Layout) */
export type StandardLayout = Layout &
  LifecycleEvents & {
    __typename?: 'StandardLayout';
    actions?: Maybe<Actions>;
    /** The classification of this page layout: `STANDARD_LAYOUT` */
    layoutType: LayoutTypes;
    /** The set of sections in the standard layout */
    sections: StandardLayoutSections;
    /** The metadata associated with this layout */
    metadata?: Maybe<LayoutMetadata>;
  };

export type VideoBackgroundLayoutSections = Sections & {
  __typename?: 'VideoBackgroundLayoutSections';
  sectionsType: SectionTypes;
  header: Component;
  background: Component;
  content: Component;
};

export type VideoBackgroundLayout = Layout &
  LifecycleEvents & {
    __typename?: 'VideoBackgroundLayout';
    actions?: Maybe<Actions>;
    layoutType: LayoutTypes;
    sections: VideoBackgroundLayoutSections;
    /** The metadata associated with this layout */
    metadata?: Maybe<LayoutMetadata>;
  };

export type LiveStreamLocalRegionModel = {
  __typename?: 'LiveStreamLocalRegionModel';
  state?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
};

export type LiveStreamMetaModel = {
  __typename?: 'LiveStreamMetaModel';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  pageHeading?: Maybe<Scalars['String']>;
};

export type LiveStreamRegionModel = {
  __typename?: 'LiveStreamRegionModel';
  id: Scalars['Float'];
  slug: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateName?: Maybe<Scalars['String']>;
  oztamPublisherId?: Maybe<Scalars['String']>;
};

export type LiveStreamLinkModel = {
  __typename?: 'LiveStreamLinkModel';
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  webUrl?: Maybe<Scalars['String']>;
};

export type LiveStreamRelatedLiveEventModel = {
  __typename?: 'LiveStreamRelatedLiveEventModel';
  name?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  genreName?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  programStartTime?: Maybe<Scalars['String']>;
  programEndTime?: Maybe<Scalars['String']>;
};

export type LiveStreamLiveEventSwitcherLogoImageModel = {
  __typename?: 'LiveStreamLiveEventSwitcherLogoImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type LiveEventImageModel = {
  __typename?: 'LiveEventImageModel';
  alt: Scalars['String'];
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type LiveStreamLiveEventModel = {
  __typename?: 'LiveStreamLiveEventModel';
  type: Scalars['String'];
  id: Scalars['Float'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  brightcoveId?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  promoMessage?: Maybe<Scalars['String']>;
  promoStartDate?: Maybe<Scalars['String']>;
  ssai?: Maybe<SsaiModel>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  oztamPublisherId?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  channelSlug?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  image?: Maybe<LiveEventImageModel>;
  logo?: Maybe<LiveEventImageModel>;
  cardImage?: Maybe<LiveEventImageModel>;
  liveCardImage?: Maybe<LiveEventImageModel>;
  upcomingCardImage?: Maybe<LiveEventImageModel>;
  link?: Maybe<LiveStreamLinkModel>;
  currentEvent?: Maybe<LiveStreamRelatedLiveEventModel>;
  nextEvent?: Maybe<LiveStreamRelatedLiveEventModel>;
  switcherLogo?: Maybe<LiveStreamLiveEventSwitcherLogoImageModel>;
  isChannelGroup?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
};

export type ChannelImageModel = {
  __typename?: 'ChannelImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type LiveStreamChannelSwitcherLogoImageModel = {
  __typename?: 'LiveStreamChannelSwitcherLogoImageModel';
  alt?: Maybe<Scalars['String']>;
  sizes?: Maybe<ImageSizesModel>;
  webpSizes?: Maybe<ImageSizesModel>;
};

export type ChannelListingModel = {
  __typename?: 'ChannelListingModel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  episodeTitle?: Maybe<Scalars['String']>;
  blackout?: Maybe<Scalars['Boolean']>;
  catalogCode?: Maybe<Scalars['String']>;
  materialKey?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  cardImage?: Maybe<ChannelImageModel>;
  endTime?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  yearProduction?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  tvSeriesSlug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LiveStreamChannelModel = {
  __typename?: 'LiveStreamChannelModel';
  id: Scalars['Float'];
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  venueId?: Maybe<Scalars['Float']>;
  oztamPublisherId?: Maybe<Scalars['String']>;
  unavailabilityMessage?: Maybe<Scalars['String']>;
  channelColour?: Maybe<Scalars['String']>;
  ssai?: Maybe<SsaiModel>;
  active?: Maybe<Scalars['Boolean']>;
  image?: Maybe<ChannelImageModel>;
  logo?: Maybe<ChannelImageModel>;
  cardImage?: Maybe<ChannelImageModel>;
  channelCardImage?: Maybe<ChannelImageModel>;
  link?: Maybe<LiveStreamLinkModel>;
  listings?: Maybe<Array<ChannelListingModel>>;
  buttons: ButtonsModel;
  drm?: Maybe<DrmModel>;
  stream?: Maybe<DrmStreamModel>;
  switcherLogo?: Maybe<LiveStreamChannelSwitcherLogoImageModel>;
};

export type TvApiLiveStream = {
  __typename?: 'TvApiLiveStream';
  meta: LiveStreamMetaModel;
  region: LiveStreamRegionModel;
  localRegion: LiveStreamLocalRegionModel;
  events?: Maybe<Array<LiveStreamLiveEventModel>>;
  channels?: Maybe<Array<LiveStreamChannelModel>>;
  channel?: Maybe<LiveStreamChannelModel>;
};

export type TvApiLiveStreamChannelArgs = {
  slug: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getTvSeriesPage: Layout;
  getHomePage: Layout;
  getRadioHomePage: Layout;
  getLXP: TvApiLiveExperience;
  getConfig: Config;
  getElementById: Tab;
  getLivestream: TvApiLiveStream;
};

export type QueryGetTvSeriesPageArgs = {
  pageStructure: PageStructureArgs;
  getTVSeriesArgs: GetTvSeriesPageArgs;
};

export type QueryGetHomePageArgs = {
  pageStructure: PageStructureArgs;
  getHomePageArgs: GetHomePageArgs;
  queryFlagsArgs?: InputMaybe<Scalars['JSON']>;
};

export type QueryGetRadioHomePageArgs = {
  pageStructure: PageStructureArgs;
  getRadioHomePageArgs: GetRadioHomePageArgs;
};

export type QueryGetLxpArgs = {
  liveExperienceArgs: TvApiLiveExperienceArgs;
};

export type QueryGetElementByIdArgs = {
  getElementInputArgs: GetElementInputTypeArgs;
};

export type QueryGetLivestreamArgs = {
  liveStreamArgs: TvApiLiveStreamArgs;
};

/** Parameters for variable page structure elements. [See a visual representation of the entire page structure](https://nine.atlassian.net/wiki/spaces/9NOW/pages/8887795879/Experience+Layer+Components+and+Layouts#API-Page-Structure) */
export type PageStructureArgs = {
  /** The classification of the page layout */
  layoutType: LayoutTypes;
  /** Parameters for variable elements in the sections of the specified layout type */
  sections: SectionsStructureArgs;
};

/** Parameters for variable elements in the sections for each layout type */
export type SectionsStructureArgs = {
  /** The classifications of sections (components) in the standard page layout */
  standardLayout?: InputMaybe<StandardLayoutSectionsArgs>;
  videoBackgroundLayout?: InputMaybe<VideoBackgroundLayoutSectionsArgs>;
};

/** Parameters for variable elements in the sections of the standard page layout */
export type StandardLayoutSectionsArgs = {
  /** The classification of header component */
  header: ComponentType;
  /** The classification of content component */
  content: ComponentType;
};

/** Parameters for variable elements in the sections of the video background page layout */
export type VideoBackgroundLayoutSectionsArgs = {
  /** The classification of header component */
  header: ComponentType;
  /** The classification of content component */
  content: ComponentType;
  background: ComponentType;
};

/** Parameters for variable elements on a TV Series Page */
export type GetTvSeriesPageArgs = {
  /** The unique short name for a TV Series e.g. `the-block` */
  slug: Scalars['String'];
  /** The unique short name for a Season e.g. `season-1` */
  seasonSlug?: InputMaybe<Scalars['String']>;
  /** Defaults to "`nsw`" */
  region?: Scalars['String'];
};

/** Parameters for a home page */
export type GetHomePageArgs = {
  /** Slug for the channel to display. Defaults to channel-9 */
  channelSlug?: Scalars['String'];
  /** Defaults to "`nsw`" */
  region?: Scalars['String'];
  streamParams?: InputMaybe<Scalars['String']>;
};

/** Parameters for a radio home page */
export type GetRadioHomePageArgs = {
  /** Defaults to "`nsw`" */
  region?: Scalars['String'];
  /** Radio station. Defaults to 2gb */
  station?: Scalars['String'];
};

export type TvApiLiveExperienceArgs = {
  slug: Scalars['String'];
  fallbackId?: InputMaybe<Scalars['String']>;
  streamParams?: InputMaybe<Scalars['String']>;
  /** Defaults to nsw */
  region?: Scalars['String'];
  offset?: InputMaybe<Scalars['Float']>;
  /**
   *
   * (Default false) Is the Livestream prominent in the page,
   * or is it displayed as a background element? This alters
   * the way advertisments are displayed.
   *
   */
  background?: InputMaybe<Scalars['Boolean']>;
};

export type GetElementInputTypeArgs = {
  variables: Scalars['JSON'];
};

export type TvApiLiveStreamArgs = {
  /** Defaults to nsw */
  region?: Scalars['String'];
  fallbackId?: InputMaybe<Scalars['String']>;
  streamParams?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Float']>;
  /**
   *
   * (Default false) Is the Livestream prominent in the page,
   * or is it displayed as a background element? This alters
   * the way advertisments are displayed.
   *
   */
  background?: InputMaybe<Scalars['Boolean']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}
