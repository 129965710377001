import classNames from 'classnames';
import { MetadataProgress } from 'common/types/graphQlTypes';
import styled from 'styled-components';

const SduiMetadataProgress = ({
  metadataProgress,
  className,
}: {
  metadataProgress: MetadataProgress;
  className?: string;
}) => {
  const { color, percent } = metadataProgress;
  return (
    <ProgressBarSection
      role="progressbar"
      aria-label="Progress"
      className={classNames('sdui_metadata_progress', className)}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={percent}
    >
      <Progress className="sdui_metadata_progress__bar" percent={percent} color={color}>
        &nbsp;
      </Progress>
    </ProgressBarSection>
  );
};

export default SduiMetadataProgress;

const ProgressBarSection = styled.div`
  width: 9.75rem;
  height: 0.2857rem;
  background-color: var(--mid-grey);
  border-radius: 7.142rem;
  margin-top: var(--space-xsmall);
`;

const Progress = styled.div<{ percent: number; color: string }>`
  width: ${({ percent }) => percent ?? 0}%;
  height: 100%;
  background-color: ${({ color }) => color ?? 'var(--dark-turquoise)'};
  border-radius: 7.142rem;
`;
