import { SSOState, configAtom, ssoFeatureFlagAtom } from 'src/store/store';
import { useAtomValue } from 'jotai';
import useUser from './useUser';

/**
 * Returns the encoded token used in API requests, if the user is authenticated, otherwise an empty string
 * @returns {string} token
 */
const useToken = (): string => {
  const [user] = useUser();
  const appConfig = useAtomValue(configAtom);
  const ssoFeatureFlag = useAtomValue(ssoFeatureFlagAtom);
  return (
    (ssoFeatureFlag &&
      appConfig?.features?.cdnTokenisation &&
      user?.type === SSOState.AUTHENTICATED &&
      user?.encodedToken) ||
    ''
  );
};

export default useToken;
